import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import FormTextAreaJson from '../../../../common/FormTextAreaJSON';
import FormFieldProject from '../../../../common/FormFieldProject';
import FormDropdownCertificate from '../../../../common/FormDropdownCertificate';
import FormGroupTimestamps from '../../../../common/FormGroupTimestamps';
import TemplateDistributionFormField from './TemplateDistributionFormField';
import { List } from 'immutable';
import FormDropdownLanguages from '../../../../common/FormDropdownLanguages';
import { connect } from 'react-redux';
import { addNotification } from '../../../../../actions/notification';
import { injectIntl } from 'react-intl';
import BooleanInput from '../../inputs/BooleanInput';

class GeneralForm extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    itemSet: PropTypes.func.isRequired,
    canUpdate: PropTypes.bool,
    changed: PropTypes.bool,
  };

  handleChange = (e, p) => {
    const { itemSet, data } = this.props;
    itemSet(data.set(p.name, p.value));
  };

  handleJsonSchemaChange = (e, p) => {
    return;
  };

  setLanguages = (e, { value }) => {
    const { itemSet, data } = this.props;
    itemSet(data.set('languages', new List(value)));
  };

  render() {
    let { data, canUpdate, changed, errors, itemSet } = this.props;

    return (
      <Form>
        <FormFieldProject projectId={data.get('projectId')} />

        <FormDropdownCertificate
          name="certificateId"
          onChange={this.handleChange}
          defaultValue={data.get('certificateId')}
        />

        <FormDropdownLanguages
          name="languages"
          value={data.get('languages').toArray()}
          multiple={true}
          onChange={this.setLanguages}
        />

        <Form.Input
          label={'Name'}
          onChange={this.handleChange}
          value={data.get('name')}
          name="name"
        />

        <BooleanInput
          key={`jsonTemplate.sharingProhibited.value`}
          itemSet={itemSet}
          data={data}
          errors={errors}
          path={['jsonTemplate', 'sharingProhibited']}
          label={'Sharing prohibited'}
        />

        <TemplateDistributionFormField
          data={data}
          canUpdate={canUpdate}
          changed={changed}
          onChange={this.handleChange}
        />

        <FormTextAreaJson
          name="dynamicDataJsonSchema"
          disabled={!canUpdate}
          label="Dynamic data JSON Schema"
          defaultValue={data.get('dynamicDataJsonSchema')}
          onChange={this.handleJsonSchemaChange}
          readOnly
        />
        <FormGroupTimestamps data={data} />
      </Form>
    );
  }
}

export default connect(state => ({}), {
  addNotification,
})(injectIntl(GeneralForm));
