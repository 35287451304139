import { Record, List, OrderedMap } from 'immutable';
import Images from './image';
import {
  createStructure,
  NumberProperty,
  ArrayType,
  ObjectType,
  BooleanProperty,
  StringProperty,
} from '../property';
import shortid from 'shortid';
import validate from './validate';
import Errors from '../Errors';

export default class Template extends Record({
  id: undefined,
  name: undefined,
  jsonTemplate: undefined,
  dynamicDataJsonSchema: undefined,
  projectId: undefined,
  certificateId: undefined,
  images: undefined,
  languages: undefined,
  mutability: undefined,
  translations: undefined,
  distributable: undefined,
  createdAt: undefined,
  updatedAt: undefined,
  deletedAt: undefined,
  valid: undefined,
  // OLD json template properties
  template: undefined,
  saveToGooglePayObjectTemplate: undefined,
}) {
  constructor(data) {
    data.images = new Images(data.images);
    data.languages = new List(data.languages);
    if (data.jsonTemplate) {
      data.jsonTemplate = createStructure(data.jsonTemplate);
    }
    super(data);
  }

  associatedIdentifierAdd(id) {
    let items = this.getIn([
      'jsonTemplate',
      'associatedStoreIdentifiers',
      'items',
    ])
      ? this.getIn(['jsonTemplate', 'associatedStoreIdentifiers', 'items'])
      : new List();
    // check if not already contains
    let contains = false;
    items.map(item => {
      if (item.get('value') === id) {
        contains = true;
      }
      return null;
    });

    if (!contains) {
      items = items.push(new NumberProperty({ value: id }));
      let associatedStoreIdentifiers = this.getIn([
        'jsonTemplate',
        'associatedStoreIdentifiers',
      ])
        ? this.getIn(['jsonTemplate', 'associatedStoreIdentifiers'])
        : new ArrayType();
      associatedStoreIdentifiers = associatedStoreIdentifiers.set(
        'items',
        items,
      );
      return this.setIn(
        ['jsonTemplate', 'associatedStoreIdentifiers'],
        associatedStoreIdentifiers,
      );
    } else {
      throw new Error(
        `AssociatedStoreIdentifiers already contains app with ID ${id}`,
      );
    }
  }

  associatedIdentifierRemove(id) {
    let newItems = new List();
    let items = this.getIn([
      'jsonTemplate',
      'associatedStoreIdentifiers',
      'items',
    ])
      ? this.getIn(['jsonTemplate', 'associatedStoreIdentifiers', 'items'])
      : new List();
    items.map((item, i) => {
      if (item.get('value') !== id) {
        newItems = newItems.push(item);
      }
      return i;
    });

    if (newItems.size > 0) {
      return this.setIn(
        ['jsonTemplate', 'associatedStoreIdentifiers', 'items'],
        newItems,
      );
    } else {
      return this.set(
        'jsonTemplate',
        this.get('jsonTemplate').delete('associatedStoreIdentifiers'),
      );
    }
  }

  barcodeChangeType(value) {
    let barcodes = this.getIn(['jsonTemplate', 'barcodes']);

    if (!barcodes) {
      barcodes = new ArrayType();
    }

    let items = barcodes.get('items');

    if (items.size) {
      items = items.setIn([0, 'properties', 'format'], value);
    } else {
      items = items.push(
        new ObjectType({
          properties: {
            format: value,
            key: { type: 'string', value: shortid.generate() },
            messageEncoding: { type: 'string', value: 'UTF-8' },
          },
        }),
      );
    }

    if (value && value.get('value') === 'PKBarcodeFormatCode128') {
      if (items.size > 1) {
        items = items.setIn([1], items.get(0));
      } else {
        const i = items.get(0).get('properties');
        items = items.push(
          new ObjectType({
            properties: {
              format: undefined,
              key: { type: 'string', value: shortid.generate() },
              messageEncoding: {
                type: 'string',
                value: i.getIn(['messageEncoding', 'value']),
              },
              message: { type: 'string', value: i.getIn(['message', 'value']) },
              altText: {
                type: 'string',
                value: i.getIn(['altText', 'value']),
              },
            },
          }),
        );
      }
    }

    // Remove fallback if disable 128
    if (
      value &&
      value.get('value') !== 'PKBarcodeFormatCode128' &&
      items.size > 1
    ) {
      items = items.delete(1);
    }
    barcodes = barcodes.set('items', items);
    return this.setIn(['jsonTemplate', 'barcodes'], barcodes);
  }

  barcodeChangeFallbackType(value) {
    let barcodes = this.getIn(['jsonTemplate', 'barcodes']);
    let items = barcodes.get('items');
    items = items.setIn([1, 'properties', 'format'], value);
    barcodes = barcodes.set('items', items);
    return this.setIn(['jsonTemplate', 'barcodes'], barcodes);
  }

  barcodeRemove() {
    return this.deleteIn(['jsonTemplate', 'barcodes']);
  }

  barcodeChangeProperty(name, value) {
    let barcodes = this.getIn(['jsonTemplate', 'barcodes']);
    if (!barcodes) {
      barcodes = new ArrayType();
    }
    let items = barcodes.get('items');
    let newItems = new List();
    items.map(bc => {
      newItems = newItems.push(
        value
          ? bc.set('properties', bc.get('properties').set(name, value))
          : bc.deleteIn(['properties', name]),
      );
      return newItems;
    });
    barcodes = barcodes.set('items', newItems);
    return this.setIn(['jsonTemplate', 'barcodes'], barcodes);
  }

  beaconAdd() {
    let beacons = this.getIn(['jsonTemplate', 'beacons']);
    if (!beacons) {
      beacons = new ArrayType();
    }
    beacons = beacons.set(
      'items',
      beacons.get('items').push(
        new ObjectType({
          properties: { key: { type: 'string', value: shortid.generate() } },
        }),
      ),
    );
    return this.setIn(['jsonTemplate', 'beacons'], beacons);
  }

  beaconRemove(index) {
    let list = this.getIn(['jsonTemplate', 'beacons', 'items']);
    list = list.delete(index);
    if (list.size > 0) {
      return this.setIn(['jsonTemplate', 'beacons', 'items'], list);
    } else {
      return this.deleteIn(['jsonTemplate', 'beacons']);
    }
  }

  beaconSetProperty(index, name, value) {
    let list = this.getIn(['jsonTemplate', 'beacons', 'items']);
    let property = list.get(index);
    if (value) {
      property = property.setIn(['properties', name], value);
    } else {
      property = property.deleteIn(['properties', name]);
    }
    list = list.set(index, property);
    return this.setIn(['jsonTemplate', 'beacons', 'items'], list);
  }

  locationAdd() {
    let locations = this.getIn(['jsonTemplate', 'locations']);
    if (!locations) {
      locations = new ArrayType();
    }
    locations = locations.set(
      'items',
      locations.get('items').push(
        new ObjectType({
          properties: { key: { type: 'string', value: shortid.generate() } },
        }),
      ),
    );
    return this.setIn(['jsonTemplate', 'locations'], locations);
  }

  locationRemove(index) {
    let list = this.getIn(['jsonTemplate', 'locations', 'items']);
    list = list.delete(index);
    if (list.size > 0) {
      return this.setIn(['jsonTemplate', 'locations', 'items'], list);
    } else {
      return this.deleteIn(['jsonTemplate', 'locations']);
    }
  }

  setInPath(path, value) {
    if (value) {
      return this.setIn(path, value);
    } else {
      return this.deleteIn(path);
    }
  }

  setImage(key, value) {
    return this.setIn(['images', key], value);
  }

  setTransitType(value) {
    return this.setInPath(
      ['jsonTemplate', this.getPassStyle(), 'properties', 'transitType'],
      value,
    );
  }

  setFieldsItems(fieldsType, items) {
    return this.setIn(
      ['jsonTemplate', this.getPassStyle(), 'properties', fieldsType, 'items'],
      items,
    );
  }

  changePassStyle(value) {
    const passStyle = this.getPassStyle();
    if (value !== passStyle) {
      let toSet = this.get('jsonTemplate')
        .setIn([value], this.get('jsonTemplate').get(passStyle))
        .deleteIn([passStyle]);
      if (value === 'boardingPass') {
        toSet = toSet.setIn(
          ['boardingPass', 'properties', 'transitType'],
          new StringProperty({
            value: 'PKTransitTypeGeneric',
          }),
        );
      } else {
        toSet = toSet.deleteIn([value, 'properties', 'transitType']);
      }
      return this.set('jsonTemplate', toSet);
    }
    return this;
  }

  setLogoText(value) {
    return this.setInPath(['jsonTemplate', 'logoText'], value);
  }

  getPassStyle() {
    const jsonTemplate = this.get('jsonTemplate');
    return [
      'boardingPass',
      'coupon',
      'eventTicket',
      'generic',
      'storeCard',
    ].find(
      item =>
        jsonTemplate.get(item) && jsonTemplate.get(item).get('properties'),
    );
  }

  getFields(fieldsType) {
    return this.getIn([
      'jsonTemplate',
      this.getPassStyle(),
      'properties',
      fieldsType,
      'items',
    ]);
  }

  fieldAdd(fieldType, key) {
    let fields = this.getIn([
      'jsonTemplate',
      this.getPassStyle(),
      'properties',
      fieldType,
    ]);
    if (!fields) {
      fields = new ArrayType();
    }
    fields = fields.set(
      'items',
      fields.get('items').push(
        new ObjectType({
          properties: { key: { type: 'string', value: key } },
        }),
      ),
    );
    return this.setIn(
      ['jsonTemplate', this.getPassStyle(), 'properties', fieldType],
      fields,
    );
  }

  removeField(fieldsType, index) {
    let fields = this.getIn([
      'jsonTemplate',
      this.getPassStyle(),
      'properties',
      fieldsType,
    ]);
    if (!fields) {
      fields = new ArrayType();
    }
    fields = fields.set('items', fields.get('items').delete(index));

    if (fields.size > 0) {
      return this.setIn(
        ['jsonTemplate', this.getPassStyle(), 'properties', fieldsType],
        fields,
      );
    } else {
      return this.deleteIn([
        'jsonTemplate',
        this.getPassStyle(),
        'properties',
        fieldsType,
      ]);
    }
  }

  fieldSwitch(fieldsType, fieldIndex, event) {
    const { droppedIndex, droppedFieldsType } = JSON.parse(
      event.dataTransfer.getData('draggedField'),
    );
    if (droppedFieldsType === fieldsType) {
      const items = this.getIn([
        'jsonTemplate',
        this.getPassStyle(),
        'properties',
        fieldsType,
        'items',
      ]);
      let toSet = items;
      if (fieldIndex < droppedIndex) {
        for (let i = fieldIndex; i < droppedIndex + 1; i++) {
          toSet = toSet.setIn(
            [i],
            items.get(i === fieldIndex ? droppedIndex : i - 1),
          );
        }
      } else {
        for (let i = droppedIndex; i < fieldIndex + 1; i++) {
          toSet = toSet.setIn(
            [i],
            items.get(i === fieldIndex ? droppedIndex : i + 1),
          );
        }
      }

      return this.setFieldsItems(fieldsType, toSet);
    } else {
      return this;
    }
  }

  fieldChangeType(fieldsType, fieldIndex, value) {
    let toSet = this.getIn([
      'jsonTemplate',
      this.getPassStyle(),
      'properties',
      fieldsType,
      'items',
      fieldIndex,
    ]);
    switch (value) {
      case 'number':
        toSet = toSet
          .setIn(['properties', 'value'], new NumberProperty({}))
          .setIn(
            ['properties', 'numberStyle'],
            new StringProperty({ value: 'PKNumberStyleDecimal' }),
          )
          .deleteIn(['properties', 'ignoresTimeZone'])
          .deleteIn(['properties', 'dateStyle'])
          .deleteIn(['properties', 'timeStyle'])
          .deleteIn(['properties', 'currencyCode']);
        break;
      case 'currency':
        toSet = toSet
          .setIn(['properties', 'value'], new NumberProperty({}))
          .deleteIn(['properties', 'ignoresTimeZone'])
          .deleteIn(['properties', 'numberStyle'])
          .deleteIn(['properties', 'dateStyle'])
          .deleteIn(['properties', 'timeStyle'])
          .setIn(
            ['properties', 'currencyCode'],
            new StringProperty({ value: 'CZK' }),
          );
        break;
      case 'text':
        toSet = toSet
          .setIn(['properties', 'value'], new StringProperty({}))
          .deleteIn(['properties', 'ignoresTimeZone'])
          .deleteIn(['properties', 'numberStyle'])
          .deleteIn(['properties', 'dateStyle'])
          .deleteIn(['properties', 'timeStyle'])
          .deleteIn(['properties', 'currencyCode']);
        break;
      case 'date':
        toSet = toSet
          .setIn(['properties', 'value'], new StringProperty({}))
          .setIn(
            ['properties', 'ignoresTimeZone'],
            new BooleanProperty({ value: true }),
          )
          .setIn(
            ['properties', 'dateStyle'],
            new StringProperty({ value: 'PKDateStyleShort' }),
          )
          .setIn(
            ['properties', 'timeStyle'],
            new StringProperty({ value: 'PKDateStyleShort' }),
          )
          .deleteIn(['properties', 'numberStyle'])
          .deleteIn(['properties', 'currencyCode']);
        break;
      default:
        toSet = toSet
          .setIn(['properties', 'value'], new StringProperty({}))
          .deleteIn(['properties', 'ignoresTimeZone'])
          .deleteIn(['properties', 'numberStyle'])
          .deleteIn(['properties', 'dateStyle'])
          .deleteIn(['properties', 'timeStyle'])
          .deleteIn(['properties', 'currencyCode']);
    }

    if (fieldsType === 'backFields' && value !== 'text') {
      toSet = toSet.deleteIn(['properties', 'attributedValue']);
    }

    return this.setIn(
      [
        'jsonTemplate',
        this.getPassStyle(),
        'properties',
        fieldsType,
        'items',
        fieldIndex,
      ],
      toSet,
    );
  }

  fieldSetProperty(fieldsType, index, name, value) {
    let list = this.getIn([
      'jsonTemplate',
      this.getPassStyle(),
      'properties',
      fieldsType,
      'items',
    ]);
    let property = list.get(index);
    if (value) {
      property = property.setIn(['properties', name], value);
    } else {
      property = property.deleteIn(['properties', name]);
    }
    list = list.set(index, property);
    return this.setIn(
      ['jsonTemplate', this.getPassStyle(), 'properties', fieldsType, 'items'],
      list,
    );
  }

  attributedValueToValue(attributedValue) {
    if (attributedValue && attributedValue.includes('<a href=')) {
      return attributedValue
        .replace(/(<a href="([^"]+)">([^<]+)<\/a>)/g, '$3: $2')
        .replace(/(<a href='([^']+)'>([^<]+)<\/a>)/g, '$3: $2');
    } else {
      return attributedValue;
    }
  }

  fieldSetAttributedValue(fieldType, index, attributedValue) {
    const languages = this.get('languages');
    let list = this.getIn([
      'jsonTemplate',
      this.getPassStyle(),
      'properties',
      fieldType,
      'items',
    ]);

    let properties = list.get(index);
    let value = properties.getIn(['properties', `value`, `value`]);
    let fallback = properties.getIn(['properties', `value`, `fallback`]);
    let equalsAttributedValueAndValue = true;

    if (attributedValue) {
      if (
        attributedValue &&
        (attributedValue.value || attributedValue.value === '') &&
        !!attributedValue.value.get
      ) {
        // all translations for current state

        if (!value.set) {
          value = new OrderedMap();
        }

        languages.map(lang => {
          const av = attributedValue.value.get(lang);
          // TODO fallback
          const v = this.attributedValueToValue(av);
          value = value.set(lang, v);

          if (v !== av) {
            equalsAttributedValueAndValue = false;
          }

          return value;
        });
      } else {
        value = this.attributedValueToValue(attributedValue.value);
        fallback = this.attributedValueToValue(attributedValue.fallback);
        equalsAttributedValueAndValue = value === attributedValue.value;
      }
    } else {
      value = undefined;
    }

    // If value is deep eauals attributed value, set only value
    if (equalsAttributedValueAndValue) {
      properties = properties.setIn(
        ['properties', 'value'],
        new StringProperty({ value: value, fallback: fallback }),
      );
      properties = properties.deleteIn(['properties', 'attributedValue']);
    } else {
      properties = properties.setIn(
        ['properties', 'value'],
        new StringProperty({ value: value, fallback: fallback }),
      );
      properties = properties.setIn(
        ['properties', 'attributedValue'],
        attributedValue,
      );
    }

    list = list.set(index, properties);
    return this.setIn(
      ['jsonTemplate', this.getPassStyle(), 'properties', fieldType, 'items'],
      list,
    );
  }

  validate() {
    const isValid = validate(this.toJSON());
    return new Errors(isValid, validate.errors);
  }

  getColor(type) {
    const property = this.getIn(['jsonTemplate', type]);
    if (property) {
      if (property.isPlaceholder('color')) {
        return property.get('fallback');
      } else {
        return property.get('value');
      }
    } else {
      return undefined;
    }
  }

  getForegroundColor() {
    return this.getColor('foregroundColor');
  }

  getBackgroundColor() {
    return this.getColor('backgroundColor');
  }

  getLabelColor() {
    return this.getColor('labelColor');
  }
}
